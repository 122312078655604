import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  // get all questionnaire template tags
  isGetAllQuestionnaireTemplateTagsLoading: false,
  questionnaireTemplateTagsData: {},
  questionnaireTemplateTagsFailedData: {},

  // search questionnaire template tag
  isSearchQuestionnaireTemplateTagLoading: false,
  questionnaireTemplateTagSearchSuccessData: {},
  questionnaireTemplateTagSearchFailedData: {},
};

/**
 * Questionnaire template tag reducer
 */
export const questionnaireTemplateTagReducer = createReducer(initialState, {
  // get all questionnaire templates
  getAllQuestionnaireTemplateTagsRequest: (state) => {
    state.isGetAllQuestionnaireTemplateTagsLoading = true;
    state.questionnaireTemplateTagsData = {};
    state.questionnaireTemplateTagsFailedData = {};
  },
  getAllQuestionnaireTemplateTagsRequestSuccess: (state, action) => {
    state.isGetAllQuestionnaireTemplateTagsLoading = false;
    state.questionnaireTemplateTagsData = action.payload;
    state.questionnaireTemplateTagsFailedData = {};
  },
  getAllQuestionnaireTemplateTagsRequestFailed: (state, action) => {
    state.isGetAllQuestionnaireTemplateTagsLoading = false;
    state.questionnaireTemplateTagsData = {};
    state.questionnaireTemplateTagsFailedData = action.payload;
  },

  // search questionnaire template tag
  postSearchQuestionnaireTemplateRequest: (state) => {
    state.isSearchQuestionnaireTemplateLoading = true;
    state.questionnaireTemplateTagSearchSuccessData = {};
    state.questionnaireTemplateTagSearchFailedData = {};
  },
  postSearchQuestionnaireTemplateRequestSuccess: (state, action) => {
    state.isSearchQuestionnaireTemplateLoading = false;
    state.questionnaireTemplateTagSearchSuccessData = action.payload;
    state.questionnaireTemplateTagSearchFailedData = {};
  },
  postSearchQuestionnaireTemplateRequestFailed: (state, action) => {
    state.isSearchQuestionnaireTemplateLoading = false;
    state.questionnaireTemplateTagSearchSuccessData = {};
    state.questionnaireTemplateTagSearchFailedData = action.payload;
  },

});

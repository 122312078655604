import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //signed url for file upload
  signedURLRequestLoading: false,
  signedURLData: {},
  signedURLFailed: {},

  //Create New Lead
  uploadFileRequestLoading: false,
  uploadFileData: {},
  uploadFileFailed: {},

  //get all sent files
  getAllUploadedSentFilesRequestLoading: false,
  uploadedSentFilesRequestSuccessData: {},
  uploadedSentFilesRequestFailedData: {},

  //get all received files
  getAllUploadedReceivedFilesRequestLoading: false,
  uploadedReceivedFilesRequestSuccessData: {},
  uploadedReceivedFilesRequestFailedData: {},
};

export const filesUploadReducer = createReducer(initialState, {
  //signed url
  postGenerateSignedURLForUploadRequest: (state) => {
    state.signedURLRequestLoading = true;
    state.signedURLData = {};
    state.signedURLFailed = {};
  },
  postGenerateSignedURLForUploadSuccess: (state, action) => {
    state.signedURLRequestLoading = false;
    state.signedURLData = action.payload;
    state.signedURLFailed = {};
  },
  postGenerateSignedURLForUploadFailed: (state, action) => {
    state.signedURLRequestLoading = false;
    state.signedURLData = {};
    state.signedURLFailed = action.payload;
  },

  //upload file
  putUploadFileRequest: (state) => {
    state.uploadFileRequestLoading = true;
    state.uploadFileData = {};
    state.uploadFileFailed = {};
  },
  putUploadFileSuccess: (state, action) => {
    state.uploadFileRequestLoading = false;
    state.uploadFileData = action.payload;
    state.uploadFileFailed = {};
  },
  putUploadFileFailed: (state, action) => {
    state.uploadFileRequestLoading = false;
    state.uploadFileData = {};
    state.uploadFileFailed = action.payload;
  },

  //get all sent files
  getAllUploadedSentFilesRequest: (state) => {
    state.getAllUploadedSentFilesRequestLoading = true;
    state.uploadedSentFilesRequestSuccessData = {};
    state.uploadedSentFilesRequestFailedData = {};
  },
  getAllUploadedSentFilesRequestSuccess: (state, action) => {
    state.getAllUploadedSentFilesRequestLoading = false;
    state.uploadedSentFilesRequestSuccessData = action.payload;
    state.uploadedSentFilesRequestFailedData = {};
  },
  getAllUploadedSentFilesRequestFailed: (state, action) => {
    state.getAllUploadedSentFilesRequestLoading = false;
    state.uploadedSentFilesRequestSuccessData = {};
    state.uploadedSentFilesRequestFailedData = action.payload;
  },

  //get all received files
  getAllUploadedReceivedFilesRequest: (state) => {
    state.getAllUploadedReceivedFilesRequestLoading = true;
    state.uploadedReceivedFilesRequestSuccessData = {};
    state.uploadedReceivedFilesRequestFailedData = {};
  },
  getAllUploadedReceivedFilesRequestSuccess: (state, action) => {
    state.getAllUploadedReceivedFilesRequestLoading = false;
    state.uploadedReceivedFilesRequestSuccessData = action.payload;
    state.uploadedReceivedFilesRequestFailedData = {};
  },
  getAllUploadedReceivedFilesRequestFailed: (state, action) => {
    state.getAllUploadedReceivedFilesRequestLoading = false;
    state.uploadedReceivedFilesRequestSuccessData = {};
    state.uploadedReceivedFilesRequestFailedData = action.payload;
  },
});

import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  documentDetails: [],
};

export const documentReducer = createReducer(initialState, {
  getDocumentsRequest: (state) => {
    state.loading = true;
    state.documentDetails = [];
  },
  getDocumentsSuccess: (state, action) => {
    state.loading = false;
    state.documentDetails = action.payload;
  },
  getDocumentsFalse: (state) => {
    state.loading = false;
    state.documentDetails = [];
  },
});

import { createReducer } from '@reduxjs/toolkit';
import config from 'config';

export const initialState = {
  isOpen: [], // for active default menu
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  opened: true,
  colorData: {
    primaryColour: null,
    secondaryColour: null,
    fontColour: null
  }
};

export const customization = createReducer(initialState, {
  customization_SET_MENU: (state, action) => {
    return {
      ...state,
      opened: action.opened,
    };
  },
  customization_MENU_TOGGLE: (state, action) => {
    return {
      ...state,
      opened: action.opened,
    };
  },
  customization_MENU_OPEN: (state, action) => {
    return {
      ...state,
      isOpen: [action.id],
    };
  },
  customization_SET_FONT_FAMILY: (state, action) => {
    return {
      ...state,
      fontFamily: action.fontFamily,
    };
  },
  customization_SET_BORDER_RADIUS: (state, action) => {
    return {
      ...state,
      borderRadius: action.borderRadius,
    };
  },
  customization_SET_WEB_FORM_COLOR_SETTINGS: (state, action) => {
    return {
      ...state,
      colorData: {
        primaryColour: action?.payload?.primaryColour,
        secondaryColour: action?.payload?.secondaryColour,
        fontColour: action?.payload?.fontColour
      },
    };
  },
});

import { createReducer } from '@reduxjs/toolkit';

const leadQuestionnairesInitialState = {
  //get Lead Questionnaires
  isGetLeadQuestionnairesLoading: false,
  leadQuestionnairesSuccessData: {},
  leadQuestionnairesFailedData: {},

  //update lead questionnaires
  isUpdateLeadQuestionnaireLoading: false,
  updateLeadQuestionnaireSuccessData: {},
  updateLeadQuestionnaireFailedData: {},

  //filter Lead questionnaires
  isFilterLeadQuestionnaireRequestLoading: false,
  filterQuestionnaireRequestSuccessData: {},
  filterQuestionnaireRequestFailedData: {},
};

//lead questionnaires reducer
export const leadQuestionnairesReducer = createReducer(leadQuestionnairesInitialState, {
  //get lead questionnaires
  getLeadQuestionnaireRequest: (state) => {
    state.isGetLeadQuestionnairesLoading = true;
    state.leadQuestionnairesSuccessData = {};
    state.leadQuestionnairesFailedData = {};
  },
  getLeadQuestionnaireRequestSuccess: (state, action) => {
    state.isGetLeadQuestionnairesLoading = false;
    state.leadQuestionnairesSuccessData = action.payload;
    state.leadQuestionnairesFailedData = {};
  },
  getLeadQuestionnaireRequestFailed: (state, action) => {
    state.isGetLeadQuestionnairesLoading = false;
    state.leadQuestionnairesSuccessData = {};
    state.leadQuestionnairesFailedData = action.payload;
  },

  //update lead questionnaires
  updateLeadQuestionnaireRequest: (state) => {
    state.isUpdateLeadQuestionnaireLoading = true;
    state.updateLeadQuestionnaireSuccessData = {};
    state.updateLeadQuestionnaireFailedData = {};
  },
  updateLeadQuestionnaireRequestSuccess: (state, action) => {
    state.isUpdateLeadQuestionnaireLoading = false;
    state.updateLeadQuestionnaireSuccessData = action.payload;
    state.updateLeadQuestionnaireFailedData = {};
  },
  updateLeadQuestionnaireRequestFailed: (state, action) => {
    state.isUpdateLeadQuestionnaireLoading = false;
    state.updateLeadQuestionnaireSuccessData = {};
    state.updateLeadQuestionnaireFailedData = action.payload;
  },

  //filter lead questionnaires
  postFilterLeadQuestionnairesRequest: (state) => {
    state.isFilterLeadQuestionnaireRequestLoading = true;
    state.filterQuestionnaireRequestSuccessData = {};
    state.filterQuestionnaireRequestFailedData = {};
  },
  postFilterLeadQuestionnairesRequestSuccess: (state, action) => {
    state.isFilterLeadQuestionnaireRequestLoading = false;
    state.filterQuestionnaireRequestSuccessData = action.payload;
    state.filterQuestionnaireRequestFailedData = {};
  },
  postFilterLeadQuestionnairesRequestFailed: (state, action) => {
    state.isFilterLeadQuestionnaireRequestLoading = false;
    state.filterQuestionnaireRequestSuccessData = {};
    state.filterQuestionnaireRequestFailedData = action.payload;
  },
});

import { createReducer } from '@reduxjs/toolkit';

const webFormSettingsInitialState = {
  //get Web Form Settings
  isGetWebFormSettingsLoading: false,
  webFormSettingsSuccessData: {},
  webFormSettingsFailedData: {},

};

//web form setting reducer
export const webFormSettingsReducer = createReducer(webFormSettingsInitialState, {

  //get web form settings
  getWebFormSettingRequest: (state) => {
    state.isGetWebFormSettingsLoading = true;
    state.webFormSettingsSuccessData = {};
    state.webFormSettingsFailedData = {};
  },
  getWebFormSettingRequestSuccess: (state, action) => {
    state.isGetWebFormSettingsLoading = false;
    state.webFormSettingsSuccessData = action.payload;
    state.webFormSettingsFailedData = {};
  },
  getWebFormSettingRequestFailed: (state, action) => {
    state.isGetWebFormSettingsLoading = false;
    state.webFormSettingsSuccessData = {};
    state.webFormSettingsFailedData = action.payload;
  },

});

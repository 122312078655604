import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import { authenticationReducer } from './Reducers/authenticationReducer';

import { documentReducer } from './Reducers/DocumentReducer';

import { customization } from './Reducers/LayoutReducer';

import { notificationReducer } from './Reducers/handleNotificationReducer';

import { modalReducer } from './Reducers/modalReducer';
import { compositeDataReducer } from './Reducers/compositeDataReducer';
import { leadsReducer } from './Reducers/leadsReducer';
import { onboardingProgressReducer } from './Reducers/onboardingProgressReducer';
import { familyAndLifestyleProtectionsReducer } from './Reducers/familyAndLifestyleProtectionsReducer';

import { clientAssetReducer } from './Reducers/clientAssetReducer';
import { clientExpenseReducer } from './Reducers/clientExpenseReducer';
import { clientIncomeReducer } from './Reducers/clientIncomeReducer';
import { clientLiabilityReducer } from './Reducers/clientLiabilityReducer';
import { goalsReducer } from './Reducers/goalsReducer';
import { leadQuestionnairesReducer } from './Reducers/leadQuestionnairesReducer';
import { filesUploadReducer } from './Reducers/filesUploadReducer';
import { organizationSettingsReducer } from './Reducers/organizationSettingsReducer';
import { webFormSettingsReducer } from './Reducers/webFormsSettingsReducer';
import { questionnaireTemplateTagReducer } from './Reducers/questionnaireTemplateTagReducer';

const authenticationPersistConfig = {
  key: 'authenticationReducer',
  storage: storageSession,
};
// app reducer
const appReducer = combineReducers({
  authenticationReducer: persistReducer(authenticationPersistConfig, authenticationReducer),
  documentReducer: documentReducer,
  customization: customization,
  notificationReducer: notificationReducer,
  modalReducer: modalReducer,
  compositeDataReducer: compositeDataReducer,
  leadsReducer: leadsReducer,
  onboardingProgressReducer: onboardingProgressReducer,
  clientAssetReducer: clientAssetReducer,
  clientExpenseReducer: clientExpenseReducer,
  clientIncomeReducer: clientIncomeReducer,
  clientLiabilityReducer: clientLiabilityReducer,
  goalsReducer: goalsReducer,
  familyAndLifestyleProtectionsReducer: familyAndLifestyleProtectionsReducer,
  leadQuestionnairesReducer: leadQuestionnairesReducer,
  filesUploadReducer: filesUploadReducer,
  organizationSettingsReducer: organizationSettingsReducer,
  webFormSettingsReducer: webFormSettingsReducer,
  questionnaireTemplateTagReducer: questionnaireTemplateTagReducer,
});

//root reducer
const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === 'DESTROY_SESSION') state = undefined;

  return appReducer(state, action);
};

const Store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});
export default Store;

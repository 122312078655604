import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const LeadsOnboarding = Loadable(lazy(() => import('views/leadsOnboarding')));

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: <LeadsOnboarding />,
    },
  ],
};

export default AuthenticationRoutes;

import { createReducer } from '@reduxjs/toolkit';

// error, warning, info, success

export const initialState = {
    progress: 0,
    completion: {

    },
    submitted: false
};

export const onboardingProgressReducer = createReducer(initialState, {
    LEAD_PROGRESS_CHANGE: (state, action) => {
        state.progress = action.payload.progress;
        state.completion = {
            ...state.completion,
            ...action.payload.completion
        };
        state.submitted = action.payload.submitted
    },
});
